
import Vue from "vue";
import { Component } from "vue-property-decorator";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb

  //@ts-ignore
} from "@coreui/vue";
import { mapGetters, mapActions } from "vuex";
import DefaultHeaderDropdownAccnt from "@/containers/DefaultHeaderDropdownAccnt.vue";
import SystemMaintenanceIndicator from "@/components/shared/SystemInMaintenanceModeIndicator.vue";

interface User {
  language: string;
  firstName: string;
  username: string;
  lastName: string;
}

@Component({
  name: "IbotechContainer",
  components: {
    AsideToggler,
    SidebarHeader,
    AppHeader,
    AppSidebar,
    TheFooter,
    Breadcrumb,
    DefaultHeaderDropdownAccnt,
    SystemMaintenanceIndicator,
    SidebarToggler,
    SidebarNav,
    SidebarMinimizer
  },
  computed: {
    ...mapGetters(["getCurrentUser", "isIbotech", "isIbotechConstructionUser", "isIbotechSignMeasurementUser", "isIbotechSignMeasurementAdmin"]),

  },
  methods: {
    ...mapActions(["checkUserAdmin", "getInfo"]),
  }
})
export default class IbotechContainer extends Vue {

  readonly getCurrentUser!: User;
  private getInfo!: Function;
  private checkUserAdmin!: Function;
  private readonly isIbotech!: boolean;
  private readonly isIbotechConstructionUser!: boolean;
  private readonly isIbotechSignMeasurementUser!: boolean;
  private readonly isIbotechSignMeasurementAdmin!: boolean;

  VERSION = process.env.VUE_APP_VERSION_IBOTECH;

  created() {
    this.checkUserRole();

    if(this.$keycloak) {

      if(this.$keycloak.tokenParsed) {
        this.getInfo(this.$keycloak.tokenParsed.sub).then(() => {
          this.setUserLang();
        });

      } else {
        console.error("CAN NOT MOUNTED. KEYCLOAK IS NOT DEFINED. PLEASE CONTACT THE ADMINISTRATOR!")
      }
    } else {
      console.error("CAN NOT MOUNTED. KEYCLOAK IS NOT DEFINED. PLEASE CONTACT THE ADMINISTRATOR!")
    }
  }

  async checkUserRole() {
    await this.checkUserAdmin("ibotech");
  }

  get name() {
    return this.$route.path;
  }

  get list() {
    let routeItems = this.$route.matched.filter((route) => route);
    return routeItems.map((item) => {
      if (item.name) {
        //@ts-ignore
        item.meta.label = this.$t(item.name);
      } else if (!item.name) {
        // Check if view is in the current component
        if (item.path === this.name) {
          item.meta.label = "";
        } else {
          // If the view is a child of the current path we ned to add a label to it
          item.meta.label = this.$t(`router.${item.path.replace("/", "")}`);
        }
      }
      return item;
    });
  }

  setUserLang() {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", this.getCurrentUser.language);
    } else {
      localStorage.setItem("lang", this.getCurrentUser.language);
    }
    this.$i18n.locale = this.getCurrentUser.language;
  }


}
